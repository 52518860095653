.light .scalar-modal-layout,
.light .scalar {
    --scalar-color-1: color-mix(
        in srgb,
        rgb(var(--primary-base-300, 180 180 180)),
        rgb(var(--dark-base, 23 23 23)) 96%
    );
    --scalar-color-2: color-mix(
        in srgb,
        var(--scalar-color-1),
        transparent calc(100% - 100% * 0.72)
    );
    --scalar-color-3: color-mix(
        in srgb,
        var(--scalar-color-1),
        transparent calc(100% - 100% * 0.4)
    );
    --scalar-color-accent: #007d9c;

    --scalar-background-1: rgb(var(--light-base, 255 255 255));
    --scalar-background-2: color-mix(
        in srgb,
        rgb(var(--primary-base-800, 30 30 30)),
        var(--scalar-background-1) 96%
    );
    --scalar-background-3: color-mix(
        in srgb,
        rgb(var(--primary-base-800, 30 30 30)),
        var(--scalar-background-1) 90%
    );
    --scalar-background-accent: #007d9c1f;
    --scalar-code-language-color-supersede: var(--scalar-color-1);
    --scalar-code-languages-background-supersede: var(--scalar-background-1);
    --scalar-border-color: color-mix(
        in srgb,
        var(--scalar-color-1),
        transparent calc(100% - 100% * 0.08)
    );

    --scalar-color-green: #0a6355;
    --scalar-color-red: #dc1b19;
    --scalar-color-yellow: #ffc90d;
    --scalar-color-blue: rgb(var(--primary-color-500, 52 109 219));
    --scalar-color-orange: #ff8d4d;
    --scalar-color-purple: #8250df;

    --scalar-scrollbar-color: rgba(255, 255, 255, 0.24);
    --scalar-scrollbar-color-active: rgba(255, 255, 255, 0.48);

    --scalar-button-1: rgb(49 53 56);
    --scalar-button-1-color: #fff;
    --scalar-button-1-hover: rgb(28 31 33);
}
.dark .scalar-modal-layout,
.dark .scalar {
    --scalar-color-1: color-mix(
        in srgb,
        rgb(var(--primary-base-700, 70 70 70)),
        rgb(var(--light-base, 255 255 255)) 100%
    );
    --scalar-color-2: color-mix(
        in srgb,
        var(--scalar-color-1),
        transparent calc(100% - 100% * 0.64)
    );
    --scalar-color-3: color-mix(
        in srgb,
        var(--scalar-color-1),
        transparent calc(100% - 100% * 0.4)
    );
    --scalar-color-accent: #50b7e0;

    --scalar-background-1: rgb(var(--dark-base, 22 22 22));
    --scalar-background-2: color-mix(
        in srgb,
        rgb(var(--primary-base-200, 200 200 200)),
        var(--scalar-background-1) 92%
    );
    --scalar-background-3: color-mix(
        in srgb,
        rgb(var(--primary-base-200, 200 200 200)),
        var(--scalar-background-1) 88%
    );
    --scalar-background-accent: #8ab4f81f;
    --scalar-code-languages-background-supersede: var(--scalar-background-1);
    --scalar-border-color: color-mix(
        in srgb,
        var(--scalar-color-1),
        transparent calc(100% - 100% * 0.08)
    );

    --scalar-color-green: #56b6c2;
    --scalar-color-red: rgb(245 124 97);
    --scalar-color-yellow: #edbe20;
    --scalar-color-blue: rgb(var(--primary-color-400, 93 138 226));
    --scalar-color-orange: #d19a66;
    --scalar-color-purple: #5203d1;

    --scalar-scrollbar-color: rgba(0, 0, 0, 0.18);
    --scalar-scrollbar-color-active: rgba(0, 0, 0, 0.36);

    --scalar-button-1: #f6f6f6;
    --scalar-button-1-color: #000;
    --scalar-button-1-hover: #e7e7e7;
}
.scalar-modal-layout,
.scalar {
    --scalar-font: initial;
    --scalar-font-code: var(--font-mono);

    --scalar-paragraph: 16px;
    --scalar-small: 14px;
    --scalar-mini: 13px;
    --scalar-micro: 12px;

    --scalar-bold: 600;
    --scalar-semibold: 500;
    --scalar-regular: 400;

    /* Font sizes for interactive applications (not rendered text content) */
    --scalar-font-size-1: 24px;
    --scalar-font-size-2: 16px;
    --scalar-font-size-3: 14px;
    --scalar-font-size-4: 13px;
    --scalar-font-size-5: 12px;

    --scalar-line-height-1: 32px;
    --scalar-line-height-2: 24px;
    --scalar-line-height-3: 20px;
    --scalar-line-height-4: 18px;
    --scalar-line-height-5: 16px;

    --scalar-app-header-height: 35px;
}
.scalar input::placeholder {
    color: var(--scalar-color-3);
}
.scalar .scalar-app-header {
    width: 100%;
    z-index: 1000;
    padding: 6px 12px 6px 12px;
    border-radius: 0.25rem 0.25rem 0 0;
    font-size: 14px;
    height: var(--scalar-app-header-height);
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: 6px;
}
.scalar .scalar-api-client {
    max-height: calc(100dvh - (100px + var(--scalar-app-header-height))) !important;
    border-radius: 8px;
}
.scalar-api-client__close {
    appearance: none;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    background: transparent;
    color: var(--scalar-color-1);
    font-size: var(--scalar-small);
    font-weight: var(--scalar-semibold);
}
.scalar-api-client__close:hover {
    cursor: pointer;
}
.scalar .scalar-app {
    background: var(--scalar-background-3);
    height: calc(100dvh - 100px);
    max-width: 1280px;
    width: 100%;
    margin: auto;
    opacity: 0;
    animation: scalarapiclientfadein 0.35s forwards;
    z-index: 1002;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}
@keyframes scalarapiclientfadein {
    from {
        transform: translate3d(0, 20px, 0) scale(0.985);
        opacity: 0;
    }
    to {
        transform: translate3d(0, 0, 0) scale(1);
        opacity: 1;
    }
}
.scalar .scalar-app-exit {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.62);
    transition: all 0.3s ease-in-out;
    z-index: 1000;
    cursor: pointer;
    animation: scalardrawerexitfadein 0.35s forwards;
}
.scalar .scalar-app-exit:before {
    content: '\00d7';
    font-family: sans-serif;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 30px;
    font-weight: 100;
    line-height: 50px;
    right: 12px;
    text-align: center;
    color: white;
    opacity: 0.6;
}
.scalar .scalar-app-exit:hover:before {
    opacity: 1;
}
@keyframes scalardrawerexitfadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.scalar-container {
    overflow: hidden;
    visibility: visible;
    position: fixed;
    bottom: 0;
    left: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scalar .url-form-input {
    min-height: auto !important;
}

.scalar .scalar-container {
    line-height: normal;
}
.scalar .scalar-app-header span {
    color: var(--scalar-color-3);
}
.scalar .scalar-app-header a {
    color: var(--scalar-color-1);
}
.scalar .scalar-app-header a:hover {
    text-decoration: underline;
}
.scalar-activate {
    width: fit-content;
    margin: 0px 0.75rem 0.75rem auto;
    line-height: 24px;
    font-size: 0.75rem;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 6px;
}
.scalar-activate-button {
    display: flex;
    gap: 6px;
    align-items: center;
    color: var(--scalar-color-blue);
    appearance: none;
    outline: none;
    border: none;
    background: transparent;
}
.scalar-activate-button {
    padding: 0 0.5rem;
}
.scalar-activate:hover .scalar-activate-button {
    background: var(--scalar-background-3);
    border-radius: 3px;
}

.scalar-app-loading {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.scalar .request-method {
    white-space: nowrap;
}
/* Use :where to lower specificity to 0 */
.scalar .custom-scroll {
    overflow-y: auto;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    -webkit-overflow-scrolling: touch;
}
@supports (-moz-appearance: none) {
    .scalar .custom-scroll {
        padding-right: 12px;
    }
}
.scalar .custom-scroll:hover {
    scrollbar-color: rgba(0, 0, 0, 0.24) transparent;
}
.dark .scalar .custom-scroll:hover {
    scrollbar-color: rgba(255, 255, 255, 0.24) transparent;
}
.scalar .custom-scroll:hover::-webkit-scrollbar-thumb {
    background: var(--scalar-scrollbar-color, var(--default-theme-scrollbar-color));
    background-clip: content-box;
    border: 3px solid transparent;
}
.scalar .custom-scroll::-webkit-scrollbar-thumb:active {
    background: var(--scalar-scrollbar-color-active, var(--default-theme-scrollbar-color-active));
    background-clip: content-box;
    border: 3px solid transparent;
}
.scalar .custom-scroll::-webkit-scrollbar-corner {
    background: transparent;
}
.scalar .custom-scroll::-webkit-scrollbar {
    height: 12px;
    width: 12px;
}
.scalar .custom-scroll::-webkit-scrollbar-track {
    background: transparent;
}
.scalar .custom-scroll::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: transparent;
    background-clip: content-box;
    border: 3px solid transparent;
}
@media (pointer: coarse) {
    .scalar .custom-scroll {
        padding-right: 12px;
    }
}
